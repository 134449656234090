$green: #174B32;
$red: #FF5E5E;
$white: #FFF7F3;
@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}


@font-face {
    font-family: 'RB Faktum Neue Extended';
    src: url('../../public/fonts/NeueFaktumExtended-Regular.woff2') format('woff2'),
        url('../../public/fonts/NeueFaktumExtended-Regular.woff') format('woff');    
}

@font-face {
    font-family: 'RB Rational Neue Mix';
    src: url('../../public/fonts/NeueRationalMix-Regular.woff2') format('woff2'),
        url('../../public/fonts/NeueRationalMix-Regular.woff') format('woff');    
}
