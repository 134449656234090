@import '../../scss/mixin.scss';


article{
    padding: 100px 10vw 0;
    @include mobile{
        padding: 50px 20px 0;
    }
    .background-image {
        width: 100%;
        aspect-ratio: 16/10;
        background-size: cover;
    }
    h2{
        color: $white;
        line-height: 132%;     
        text-align: left;
    }
    p{
        padding-top: none;
    }
    .copy-wrapper{
        display: flex;
        padding: 0px 0;
        gap: 20px;
        flex-direction: column;
        a{
            text-decoration: underline;
            color: $white;
        }
    }

    .return-to-all{
        width: 250px;
        background-color: $red;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-top: 60px;
        @include mobile{
            margin-top: 20px;
        }

        a{
            text-decoration: none;
        }
        p{
            text-decoration: none;
            color: $green;
        }
    }

    .content-wrapper{
        display: flex;
        width: 100%;
        padding-top: 50px;
        gap: 60px;
        @include mobile{
            flex-direction: column;
            gap: 20px;
        }
        .title{
            width: 40%;
            @include mobile{
                width: 100%;
            }
        }
        .content{
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @include mobile{
                width: 100%;
            }
        }
    }
}