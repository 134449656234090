@import "../scss/mixin.scss";

.mouse{
    position: absolute;
    width: 50px;
    top: 50%;
    z-index: 1000;
    svg{
        width: 50px;
        height: 100px;               
        color: white;
    }
}
.intro-anim {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    @include mobile{
        height: calc(100vh - 75px);
    }
    overflow: hidden;
    h1 {
        padding: 0.25em 0 0;
        position: absolute;
        top: 0px;
    }
    .asterisk {
        gap: 40px;
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        @include mobile {
            padding-bottom: 20px;
            gap: 10px;
        }
        p {
            &:first-child {
                text-align: right;
            }
            width: 150px;
        }
        img {
            width: 75px;
            @include mobile {
                height: 50px;
            }
        }
    }
}

.falling-rectangles {
    .arrow{        
        position: absolute;
        left: 25px;
        display: flex;
        
        align-items: flex-end;
        @media screen and (min-width: 1200px) and (max-height: 780px) {            
            svg{height: 80px;                                   }
        }
        @include mobile {
            svg{height: 100px;padding-bottom: 10px;}
            
            left: 100px;
        }
       bottom: 20px;
        @include mobile{
            top: unset;
            bottom: 0px;
        }             
        z-index: 1000;
        p{
            font-size: 30px;
            color: $green;
            text-transform: uppercase;
            padding-bottom: 10px;
            margin-left: -40px;
            @include mobile{
                font-size: 20px;
                padding-bottom: 30px;
            }
            @media screen and (min-width: 1200px) and (max-height: 720px) {            
                padding-bottom: 5px;
                
            }
        }
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
    height: calc(100vh - 75px);
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 800px;
    @include mobile{
        min-height: 200px;
    }

    .rectangle {
        width: 100%;
        height: 80px;          
        @media screen and (min-width: 1200px) and (max-height: 720px) {            
            height: 70px;            
        }
        background-color: $red;
        position: relative;
        cursor: pointer;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;        
        width: 1000px;          
        @include tablet {
            width: 700px;
        }
        @include mobile {
            width: 300px;
        }        

        h2{
            height: 100%;
            display: flex;
            align-items: center;

        }
        &:first-child {            
            &:not(.active) {            
                animation: setColorWhite 5s ease infinite;
                animation-delay: 2s;
            }            
            h2 {

                padding-left: 200px;                
                @include tablet {
                    padding-left: 100px;
                }
                @include mobile {
                    padding-left: 0;
                }
            }
        }
        &:nth-child(2) {
            h2 {
                padding-right: 200px;
                @include tablet {
                    padding-right: 100px;
                }
                @include mobile {
                    padding-right: 0;
                }
            }
        }
        &:nth-child(3) {
            h2 {
                padding-left: 200px;
                @include tablet {
                    padding-left: 100px;
                }
                @include mobile {
                    padding-left: 0;
                }
            }
        }
        &:nth-child(4) {
            h2 {
                padding-right: 200px;
                @include tablet {
                    padding-right: 100px;
                }
                @include mobile {
                    padding-right: 0;
                }
            }
        }

        h2 {
            color: $green;
        }
        transition: width 0.5s;            
        &.active:hover {
            &:not(:first-child) {
                &.hoverEnabled{
                    max-width: unset;
                    width: 100%;                    
                    cursor: pointer;
                    transform: translateX(0) !important;
                    h2 {
                        padding: none;
                    }
                }
            }
        }
        .marquee {
            display: none !important;
            align-items: center;
            will-change: transform;
            background-color: $white;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;                     
            .rfm-marquee {
                --min-width: auto !important;
            }

            h3 {
                width: fit-content;
                white-space: nowrap;
                color: $green;
                display: flex;
                align-items: center;
                @include mobile {
                    font-size: 16px;
                }
            }
            img {
                width: 50px;
                margin: 0 25px;
                @include mobile {
                    width: 25px;
                }
            }
        }
        &:hover {
            &:not(:first-child) {
                h2 {
                    display: none;
                }
            }
            .marquee {     
                &.hoverEnabled{
                    display: flex !important;
                }                       
            }
        }
    }
}
.slots {
    position: absolute;
    top: calc(50% - 120px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 1200px;
    @include tablet {
        width: 800px;
        align-items: center;
    }
    @include mobile {
        align-items: center;
        width: 350px;
        padding: 0 20px;
    }
    .slot {
        width: 1000px;

        @include tablet {
            width: 700px;
        }
        @include mobile {
            width: 300px;
        }
        height: 75px;
        @media screen and (min-width: 1200px) and (max-height: 720px) {            
            height: 60px;            
        }
        border: 3px dotted $red;

        &.white {
            border-color: $white;
        }
        &.active{
            border: none;
        }
        &:first-child {            
            animation: setBorderWhite 2s forwards;
            animation-delay: 2s;
            margin-right: 200px;
            @include tablet {
                margin-right: 100px;
            }
            @include mobile {
                margin-right: 50px;
            }
        }   
        &:nth-child(2n) {
            margin-left: 200px;
            @include tablet {
                margin-left: 100px;
            }
            @include mobile {
                margin-left: 50px;
            }
        }
        &:nth-child(3) {
            margin-right: 200px;
            @include tablet {
                margin-right: 100px;
            }
            @include mobile {
                margin-right: 50px;
            }
        }
        &:hover {
            margin: none;
        }
    }
}

.marquee {
    display: none !important;
    align-items: center;
    will-change: transform;
    background-color: $white;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    .rfm-marquee {
        --min-width: auto !important;
    }

    h3 {
        width: fit-content;
        white-space: nowrap;
        color: $green;
        @include mobile {
            font-size: 12px;
        }
    }
    img {
        width: 50px;
        margin: 0 25px;
        @include mobile {
            width: 25px;
        }
    }
}


@keyframes setColorWhite {
    0% {
        background-color: $red;
    }
    50% {
        background-color: white;
    }
    100% {
        background-color: $red;
    }
}
@keyframes setRedWhite {
    0% {
        color: white;
    }
    50% {
        color: $red;
    }
    100% {
        color: white;        
    }
}
@keyframes setBorderWhite {
    from {
        border-color: $red;
    }
    to {
        border-color: white;
    }
}