@import "../../scss/mixin.scss";


footer{
    padding: 80px 0;
    @include mobile{
        padding: 0px 0 40px;
    }
    .footer-wrapper{
        max-width: 1000px;
        width: 100%;
        padding: 0 40px;
        .title{
            padding: 0px 0px 27px;
        }
        @include mobile{
            padding: 0 20px;
        }
        .copyright{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .newsletter{
            display: flex;
            gap: 10px;
            width: 100%;
            @include mobile{
                flex-direction: column;
                gap: 20px;
            }
            .validate{
                display: flex;
                gap: 10px;
                width: 100%;
            }
            .input-wrapper{
                width: 40%;
                @include mobile{
                    width: 100%;}
            }
            .base-Input-input{
                background-color: transparent;
                border: 1px solid $red;
                padding: 0.5em 1em;
                accent-color: $red;
                color: $white;      
                height: 40px;                          
                width: 100%;
            }
            .btn-wrapper{
                background-color: $red;
                padding: 0.5em 1em;
                height: 40px;     
                min-width: 100px;    
                width: 20%;
                display: flex;
                align-items: flex-end;
                cursor: pointer;
                &:hover{
                    opacity: 0.8;
                }
                @include mobile{
                    height: 30px;
                }
                button{
                    all: unset;
                    width: 100%;
                }
                p{
                    color: $green;
                }
            }
        }

        .contacts{
            display: flex;
            flex-direction: column;
            gap: 60px;
            padding: 128px 0;
            @include mobile{
                padding: 80px 0;
            }
            .agents-hero{
                display: flex;
                gap: 125px;
                @include mobile{
                    flex-direction: column;
                    gap: 40px;
                }
                .agents{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;                    
                    img{
                        height: 40px;
                        width: fit-content;
                    }
                    .agent{
                        display: flex;
                        flex-direction: column;
                        gap: 2px;         
                        @include mobile{
                            gap: 5px;
                        }               
                    }
                }

            }
            .development{
                display: flex;
                flex-direction: column;
                gap: 40px;
                .dev{
                    display: flex;
                    gap: 24px;
                }
            }
        }

    }
}

.dev-wrapper{
    display: flex;
    gap: 100px;
    @include mobile{
        flex-direction: column;
        gap: 40px;
    }
}