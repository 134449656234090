@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
}

body{
    background-color: $green;
}

a{
    text-decoration: none;
    color: $white;
    &:hover{
        text-decoration: underline;
    }
}

h1 {
    color: #faf5f3;
    text-align: center;
    font-family: "RB Faktum Neue Extended";
    font-size: 133.509px;
    font-style: normal;
    font-weight: 500;
    line-height: 138px; /* 96.161% */
    letter-spacing: -0.783px;
    @media screen and (max-width: 1350px){
        font-size: 100px;
        line-height: 100%;
    }
    @include tablet{
        font-size: 100px;
        line-height: 100%;
    }
    @include mobile {
        font-size: 50px;
        line-height: 100%;
    }
}

h2 {
    text-align: center;
    font-family: "RB Faktum Neue Extended";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 85.181px; /* 236.614% */
    letter-spacing: -0.976px;
    @include tablet{
        font-size: 28px;
        line-height: 100%;
    }
    @include mobile {
        font-size: 20px;
        line-height: 100%;
    }
}

h3 {
    color: #fff7f3;
    font-family: "RB Rational Neue Mix";
    font-size: 20.448px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.212px; /* 133.081% */
    letter-spacing: -0.545px;
    @include tablet{
        font-size: 18px;
        line-height: 100%;
    }
    @include mobile {
        font-size: 18px;
        line-height: 100%;
    }
}

p {
    color: #fff7f3;
    font-family: "RB Faktum Neue Extended";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 140% */
    letter-spacing: -0.533px;
    @include mobile {
        font-size: 14px;
        line-height: 100%;
    }
}

.contain{
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;    
}
.contain-two{
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;    
}

.contain-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.underline{
    text-decoration: underline;
}



/* The green overlay */
.transition-overlay {
    position: fixed;
    left: 0;
    bottom: 100%; /* Initially off-screen at the bottom */
    width: 100%;
    height: 0%; /* Start with no height */
    background-color: $green;
    z-index: 10;
    pointer-events: none; /* Prevent blocking content */
    transition: none; /* Disable CSS transitions to ensure GSAP controls it */
  }
  

  