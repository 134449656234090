@import "../../scss/mixin.scss";

.burger {
    position: absolute;
    top: 20px;
    right: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    z-index: 1000;
    @include mobile{
        right: 10px;        
    }
    cursor: pointer;
    .line {
        width: 80%;
        height: 3px;
        background-color: $white;
        display: flex;
        &:first-child {
            margin-left: -5px;
        }
        &:nth-child(2) {
            margin-left: 5px;
        }
        &:nth-child(3) {
            margin-left: -5px;
        }
        &:nth-child(4) {
            display: none;
            position: absolute;
            top: 50%;                        
        }
    }
    // .circle{
    //     background-color: $red;
    //     border-radius: 50%;
    //     width: 5px;
    //     height: 5px;
    //     display: none;
    //     position: absolute;
    //     scale: 0;
        
    //     justify-content: center;
    //     align-items: center;
    //     top: calc(50% - 5px);
    //     left: calc(50% - 15px);
    // }
}

.nav {
    background-color: white;
    width: 100%;    
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;
    padding: 0 150px;
    @include mobile{
        justify-content: flex-end;
        padding: 0 75px;
        gap: 20px;
    }
    a {
        text-decoration: none;
        p {
            color: $green;
            &.active{
                border-bottom: 2px solid $red;
            }
        }
    }
}
