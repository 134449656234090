

.video{
    padding: 60px 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video{
        width: 100%; 
    }
}