@import "../../scss/mixin.scss";

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 100px 40px 60px;
        @include tablet {
            padding: 60px 40px 20px;
        }
        @include mobile {
            padding: 60px 0px;
        }

        width: 100%;
        max-width: 1000px;        
        :first-child {
            width: calc((151 / 355) * 100%);
        }
        :nth-child(2) {
            width: calc((49 / 355) * 100%);
        }
        :nth-child(3) {
            width: calc((156 / 355) * 100%);
        }
    }
}

.film {
    width: 100%;    
    position: relative;   
    
    video{
        width: 100%;
        object-fit: cover;
    }    

    .pin{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 0;
        width: 200px;
        @include tablet{
            width: 150px;
        }
        @include mobile{
            width: 75px;
        }
        &:nth-child(2){ //rental apartments top left
            bottom: 78%;
            left: 20%;
            width:205px;
            @include tablet{                
                left: 10.5%;
            }
            @include mobile{
                bottom: 70%;
                left: 12.5%;
                width:80px;
            }
        }
        &:nth-child(3){ // rental apartments bottom
            bottom: 48.5%;
            left: 36.5%;
            width:205px;
            @include tablet{                
                left: 24%;
            }
            @include mobile{
                bottom: 32%;
                left: 38.5%;
                width:80px;
            }
        }
        &:nth-child(4){ // public park
            bottom: 62.5%;
            left: 35%;
            width:140px;
            @include tablet{                
                left: 32%;
            }
            @include mobile{
                bottom: 55%;
                left: 32.5%;
                width:60px;
            }
        }
        &:nth-child(5){ // workspace
            bottom: 81.5%;
            left: 51.5%;
            @include mobile{
                bottom: 70%;
                left: 52%;
                width:80px
            }
        }
        &:nth-child(6){ // creche
            bottom: 30.5%;
            left: 80.5%;
            width: 120px;
            @include tablet{                
                left: 75.5%;                
                bottom:27%;
            }
            @include mobile{
                bottom: 28%;
                left: 77%;
                width: 50px;
            }
        }
        
        &:nth-child(7){ //MY north
            bottom: 77.5%;
            left: 37.5%;
            width: 120px;
            @include tablet{                
                left: 37.5%;                
            }
            @include mobile{
                bottom: 28%;
                left: 77%;
                width: 50px;
                display:none;
            }
        }
        &:nth-child(8){ // MY Central
            bottom: 62%;
            left: 46.5%;
            width: 120px;
            @include tablet{                
                left: 46.5%;                
            }
            @include mobile{
                bottom: 28%;
                left: 77%;
                width: 50px;
                display:none;
            }
        }
        &:nth-child(9){ // MY South
            bottom: 44%;
            left: 59%;
            width: 120px;
            @include tablet{                
                left: 59%;                
            }
            @include mobile{
                bottom: 28%;
                left: 77%;
                width: 50px;
                display:none;
            }
        }
        .box{
            background-color: $white;
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            p{
                padding: 10px 20px;
                color: $green;
                font-size:14px;
                line-height:1.2em;
                @include tablet{
                    padding: 4px 6px;
                    font-size: 12px;
                    line-height:1.2em;
                }
                @include mobile{
                    padding: 5px 10px;
                    font-size: 10px;
                }
            }
            &.redbox {
                background-color:$red;
                p {
                    color:$white;
                    padding:5px;
                }
            }
        }
        
        .box-line{
            width: 2px;
            height: 30px;
            background-color: $white;       
            bottom: 0;
            @include tablet{                
                height:20px;
            }
            @include mobile{
                height: 15px;
            }                                 
        }
        .asterisk{            
            width: 20px;
            height: 20px;
            background-size: cover;
            margin-top: 5px;
        }
        
        &.redbox {
            .box {
                background-color:$red;
                p {
                    color:$white;
                    padding:5px;
                }
            }
        }
    }
}

.test{
    position: relative;
    z-index: 1000;
}

.replay-btn{
    position: absolute;
    bottom: 50px;    
    right: 50px; 
    display: flex;   
    gap: 20px;
    background-color: $red;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    @include mobile{
        padding: 5px 10px;
    }
    p{
        color: $green;
        font-size: 10px;
    }
    @include mobile{
        bottom: 20px;
        right: 20px;
    }
    svg{
        
        width: 30px;
        height: 30px;
        @include mobile{
            width: 20px;
            height: 20px;
        }                
    }
}
