@import "../../scss/mixin.scss";

.news-hero{
    min-height: 100vh;
}

.news-page {

    h1 {
        padding: 0.5em 0 0;
        @include mobile{
            padding: 1em 0 0.5rem;
        }
    }    
    h2 {
        color: $white;
        text-decoration: none;
        text-align: left;
        line-height: 121.875%;
    }
    a {
        text-decoration: none;
    }
    .read-more {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        background-color: $red;
        cursor: pointer;
        p {
            text-decoration: none;
            color: $green;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
ul {
    display: flex;
    gap: 50px;
    padding: 0 50px;
    
    flex-wrap: wrap;    
    li {
        width: calc((100% - 100px) / 3);
        @include mobile{
            width: 100%;
            margin: 10px 20px;

        }
        display: flex;
        gap: 20px;
        flex-direction: column;
        
        justify-content: space-between;
        .background-image {
            width: 100%;
            aspect-ratio: 16/10;
            background-size: cover;
        }
        .flex-top{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        
    }
    .intro {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
        @include mobile{
            padding: 40px 0 10px;
        }
        li {
            width: 100%;
            margin: 0 10vw;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            gap: 60px;
            @include mobile{
                display: flex;
                flex-direction: column-reverse;
                gap: 20px;
                margin: 0 20px;
            }
            .copy-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 50%;                                
                @include mobile{
                    width: 100%;
                }
                p {
                    width: 85%;
                    @include mobile{
                        width: 100%;
                    }
                }
                .copy-flex{
                    justify-content: flex-start;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    @include mobile{                                                
                        gap: 20px;
                        padding-bottom: 20px;  
                        h2{
                            order: 1;
                        }            
                        .date{
                            order: 2;
                        }          
                        .copy{
                            order: 3;
                        }
                    }
                }
            }
            .background-image {
                width: 50%;
                aspect-ratio: 16/10;
                background-size: cover;
                @include mobile{
                    width: 100%;
                }
            }
        }
    }
}
